import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import ReactFileReader from 'react-file-reader';
import TalkListItem from '../component/TalkListItem'; 
import JSZip from 'jszip';
import FileSaver from 'file-saver';

@withRouter
@inject("store")
@observer
export default class Recorder extends Component
{
    state = {"show_screen":false,"talks":[]};
    
    componentDidMount()
    {
        window.addEventListener("beforeunload", (e)=> { 
            if(!window.confirm("确定要离开？"))
            {
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
         });
    }
    async loadFile( e )
    {
        const thefile = e[0];
        const ext = thefile.name.split('.').pop() ;
        let talks = [];
        if( ext == 'json' )
        {
            const data = JSON.parse(await thefile.text());
            
            data.forEach( action =>
            {
                if( action.type == 'slide' && action.talks )
                {
                    talks = talks.concat( action.talks );
                }
            });
        }
        else
        {
            let tid = 8888;
            const lines =  String(await thefile.text()).split("\n");
            lines.forEach( line =>
            {
                if(line.length>=2)
                {
                    talks.push( { "id":tid,"subtitle_text":line,"audio_unikey":tid } );
                    tid++;
                }     
            });
        }
       


        

        this.props.store.max_key += talks.length;
        
        window.localStorage.setItem("TR_MAX_KEY",this.props.store.max_key);

        this.setState({talks});
    }

    async downzip()
    {
        // 遍历 map
        const zip = new JSZip();
        let listtext = '';
        // zip.file(  )
        for( let [key, value] of this.props.store.wav_data.entries() )
        {
            const blob = await (await fetch(value.url)).blob();
            console.log( "theblob" , blob );
            zip.file( key+'.wav' , blob );
            listtext += key + "\t" + value.text+"\n";
        }

        //console.log( listtext );
        // zip.file( key+'.txt' , listtext );

        /*
        this.props.store.wav_data.forEach( async ( value, key )=>
        {
            const blob = await (await fetch(value)).blob();
            console.log( "theblob" , blob );
            zip.file( key+'.wav' , blob );
            //zip.file( key+'.wav' , value , { base64:true } );
        });
        */

        //console.log( "zip" , await zip.generateAsync( {type:"blob"} ) );
        const prefix = 'TR-'+ Date.now();

        FileSaver.saveAs( await zip.generateAsync( {type:"blob"} ), prefix+'.rec.zip' );

        FileSaver.saveAs( new Blob([listtext],{
            type: "text/plain;charset=UTF-8-BOM"
          }), prefix+'.rec.txt' );

        // console.log( this.props.store.wav_data );
    }

    render()
    {
        let key = parseInt(this.props.store.max_key);
        const main = <div className="recorder">
        <div className="mainbox">
            { this.state.show_screen && <div className="talkscreen">
2
            </div> }
            <div className="talklist">
            { this.state.talks && this.state.talks.map( item =>
                {
                    item.id = ++key;
                    if( this.props.store.use_number_key ) item.audio_unikey = item.id;
                    return <TalkListItem data={item} key={item.id} />
                }  )}
            </div>
        </div>    
        <div className="toolbar">
        <ul className="iconlist">
            <li>
                <center><img src="/logo.png" className="logo"/></center>
            </li>
            <li>
            <ReactFileReader fileTypes={[".json",".txt"]} handleFiles={(e)=>this.loadFile(e)}>
            <Button icon="document-open" large={true} minimal={true} text="打开" />
            </ReactFileReader>
                
            </li>
            <li>
                <Button icon="archive" large={true} minimal={true} text="打包" onClick={()=>this.downzip()} />
            </li>
            {/* <li>
                <Button icon="modal" large={true} minimal={true} text="提示" onClick={()=>this.setState({"show_screen":!this.state.show_screen})}/>
            </li> */}
        </ul>
        </div>
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}