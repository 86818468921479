import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import FileSaver from 'file-saver';

@withRouter
@inject("store")
@observer
export default class TalkListItem extends Component
{
    
    state = {"voice_url":null};
    
    componentDidMount()
    {
        
    }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    recit( filename )
    {
        const rec = this.props.store.rec;
        rec.open(
            ()=>{
                //成功
                console.log("授权成功");
                // 开始录制
                rec.start();
                this.props.store.rec_filename = filename;
                // 最长 15 秒，自动停止
                this.timer = window.setTimeout( ()=>
                {
                    if( this.props.store.rec_filename )
                    this.recstop( filename ) 
                }, 15*1000 );
            
            },()=>
            {
                // 拒绝
            }

        );

    }

    recstop( filename )
    {
        const rec = this.props.store.rec;
        rec.stop( (blob, duration) =>
        {
            // console.log( "data", blob );
            rec.close;
            // 对 blob 进行降噪



            const dataurl = (window.URL||webkitURL).createObjectURL(blob);
            this.setState({"voice_url":dataurl});

            this.props.store.wav_data.set( filename ,{"url":dataurl,"text":this.props.data.subtitle_text}  );
            this.props.store.rec_filename = null;

            window.clearTimeout( this.timer );

            this.recplay( dataurl );

        },( msg )=>
        {
            console.log("结束录音失败:"+msg);
            this.props.store.rec_filename = null;
            rec.close;
            window.clearTimeout( this.timer );
        });
    }

    recplay( url )
    {
        const audio = this.props.store.audio_node;
        audio.src = url;
        audio.play(); 
    }

    recdownload( url, name )
    {
        FileSaver.saveAs( url, name );
    }
    
    render()
    {
        let item = {...this.props.data};
        
        // console.log( item );

        return !item ? null : <div className="item">
            <div className="filename">
            {item.audio_unikey}
            </div>
            <div className="text">
            {item.subtitle_text}
            </div>
            <div className="audio">
        
            {
                this.props.store.rec_filename == item.audio_unikey ? <Button icon="stop" onClick={()=>this.recstop( item.audio_unikey )}/> : <Button icon="record" onClick={()=>this.recit( item.audio_unikey )}/>
            }

            { this.state.voice_url && <>
            <Button icon="play" onClick={()=>this.recplay( this.state.voice_url )}/>
            <Button icon="download" onClick={()=>this.recdownload( this.state.voice_url , item.audio_unikey+'.wav' )}/>
            </> }

            
            </div>
        </div>;
    }
}