import { observable } from "mobx";
import Recorder from 'recorder-core';
import 'recorder-core/src/engine/wav';
import '../audio';

class AppState
{
    @observable appname = "⏺ 课兔录音机";   
    @observable rec_filename = "";   
    @observable use_number_key = true;   
    @observable wav_data = new Map();   
    @observable max_key = 0;   
    
    constructor()
    {
        const max_key = parseInt( window.localStorage.getItem("TR_MAX_KEY") );
        if( max_key > 0 ) this.max_key = max_key;
        
        
        Recorder.Traffic = null;
        // 初始化录音器
        this.rec = Recorder({
            type:"wav",
            sampleRate:16000,
            bitRate:16,
            onProcess:function(buffers,powerLevel,bufferDuration,bufferSampleRate,newBufferIdx,asyncEnd){
                //录音实时回调，大约1秒调用12次本回调
                //可利用extensions/waveview.js扩展实时绘制波形
                //可利用extensions/sonic.js扩展实时变速变调，此扩展计算量巨大，onProcess需要返回true开启异步模式
            }
        });
        
        this.audio_node = document.createElement("audio");
        this.audio_node.id = "theaudio";
        this.audio_node.controls=true;
        document.body.appendChild(this.audio_node);
    }
}

export default new AppState();